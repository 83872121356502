<template>
  <el-dialog
    :append-to-body="true"
    :visible.sync="showWindow"
    :destroy-on-close="true"
    title="Создание геометрии по координатам"
    class="create-geometry-by-vertices-dialog"
  >
      <vertex-edit-panel
        :map="map"
        :layer="layer"
        :srsId="selectedCS"
        :srsIds="userCSs"
        type="add"
        ref="vertex-edit-panel"
        style="height: 100%"
      ></vertex-edit-panel>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showWindow = false">Отмена</el-button>
      <el-button type="primary" @click="save">Создать</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import VertexEditPanel from '@/services/MapEditor/infrastructure/components/VertexEditPanel'
  import CoordinateSystem from '@/core/infrastructure/components/CoordinateSystem'

  export default {
    name: 'createGeometryByVerticles',
    components: {
      CoordinateSystem,
      VertexEditPanel
    },
    props: {
      map: null,
      layer: {
        type: Object
      },
      srsId: {
        type: Number,
        default: 4326
      },
      userCSs: {
        type: Array,
        default: () => {
          return []
        }
      },
      registerCS: {
        type: Function
      }
    },
    data: function () {
      return {
        selectedCS: this.srsId,
        isCSSelected: false,
        showWindow: false
      }
    },
    watch: {
      showWindow: {
        handler: function (newValue) {
          if (newValue === true) {
            if (this.userCSs.length === 0) {
              this.userCSs.splice(0, 0, { label: '(EPSG:4326) WSG-84', value: 4326})
            }
          }
        }
      }
    },
    methods: {
      show () {
        this.showWindow = true
      },
      save () {
        this.$emit('geometry-added', true, this.$refs['vertex-edit-panel'].submit())
        this.showWindow = false
      }
    }
  }
</script>

<style scoped>
  .create-geometry-by-vertices-dialog { }
  .create-geometry-by-vertices-dialog /deep/ .el-dialog__body {
    height: 400px;
  }
</style>
